body {
  font-family: 'Roboto';
  background: rgb(255,255,255);
  background: linear-gradient(360deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 0%, rgba(241,114,128,1) 100%);
  background-repeat: no-repeat;
  background-size: cover;
}

.responsive {
  width: auto;
  max-width: 20%;
  height: auto;
}

@media(max-width: 700px) {
  .responsive {
    width: auto;
    max-width: 60%;
    height: auto;
  }
}

a {
  text-decoration: none;
  color: white;
}

@media(max-width: 1366px) {
  .phones {
    width: 300px;
  }
}

.responsive2 {
  width: 100%;
  height: auto;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.icon {
  animation: updown 2s ease infinite;
}

@keyframes updown {
  0% {
    transform: translateY(-25%);
  }

  50% {
    transform: translateY(25%);
  }

  100% {
    transform: translateY(-25%);
  }
}

ul {
  list-style-type: none;
}
